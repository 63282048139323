html {
  margin: 0;
  padding: 0;
  height: auto;
}

.App, html {
  background-color: #F7F5F5;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.form {
  display: flex;
  flex-direction: column;
}

.contentStyle{
  display: flex;
  flex-direction: row;
  box-shadow: 0 10px 18px #00000029;
  flex-wrap: wrap;
  border-radius: 5px;
  padding: 10px;
  background: #fff;
}

@media (min-width: 770px) {
  .menuContainerStyle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    width: 100%;
  }

  .ui.form {
    width: 90%;
    max-width: 1330px;
  }

  .helpContainer {
    width: 40%;
    border-left: solid #e6e6e6 1px;
    padding: 20px;
    padding-top: 40px;
  }

  .informationContainer {
    width: 60%;
    padding: 3%;
  }
}

@media (max-width: 1076px) {
  .menuContainerStyle {
    display: none;
  }
}

@media (max-width: 769px) {
  .menuContainerStyle{
    display: none;
  }

  .ui.form {
    width: 100%;
  }

  .helpContainer {
    visibility: hidden;
  }

  .informationContainer {
    width: 100%;
    padding: 3%;
    align-items: center;
  }
}

@media (max-width: 488px) {
  .imgStyle {
    width: 100%;
  }
}

input::-webkit-input-placeholder {
  color: #B5B5B5 !important;
}

input:-moz-placeholder { /* Firefox 18- */
  color: #B5B5B5 !important;
}

input::-moz-placeholder {  /* Firefox 19+ */
  color: #B5B5B5 !important;
}

input:-ms-input-placeholder {
  color: #B5B5B5 !important;
}

.ui.steps .step, .ui.steps .step:first-child {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  padding-left: 5px;
}

.ui.steps .step .title {
  text-align: center;
}

.ui.steps .step > .icon {
  margin: 0
}